export const sortOrder = {
  asc: 'ascending',
  desc: 'descending'
}

export const sortProperties = {
  created: 'created',
  name: 'name',
  operation: 'operation'
}
